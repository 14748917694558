import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { createStore, setStateHelper } from "config/store";
import { UseBoundStore } from "zustand";

const initialState = {
  company: undefined,
  documentsList: [],
  selectedDocument: undefined,
  bulk: undefined,
  loadingPage: false, //savingForm
  loadingDocuments: false,
  disableFormButtons: false, //savingData
  refetchQueries: false,
  screens: {},
  showPreview: true,
};

interface BulkStore {
  company: CompanyType;
  documentsList: DocumentType[];
  selectedDocument: DocumentType;
  bulk: BulkType;
  loadingPage: boolean;
  loadingDocuments: boolean;
  disableFormButtons: boolean;
  refetchQueries: boolean;
  screens: Partial<Record<Breakpoint, boolean>>;
  showPreview: boolean;
  bulkOwnerChanged: boolean;
  resetStore: () => void;
  setSelectedDocument: (selectedDocument: DocumentType) => void;
  setDocumentsList: (documentsList: DocumentType[]) => void;
  setCompany: (company: CompanyType) => void;
  setBulk: (bulk: BulkType) => void;
  setLoadingPage: (loadingPage: boolean) => void;
  setLoadingDocuments: (loadingDocuments: boolean) => void;
  setDisablePageButtons: (disableFormButtons: boolean) => void;
  setRefetchQueries: (refetchQueries: boolean) => void;
  setScreens: (screens: Partial<Record<Breakpoint, boolean>>) => void;
  setShowPreview: (showPreview: boolean) => void;
  setBulkOwnerChanged: (bulkOwnerChanged: boolean) => void;
}

const useBulkStore: UseBoundStore<BulkStore> = createStore<BulkStore>(
  (set: (fn: (draft: Record<string, any>) => void) => void) => ({
    ...initialState,
    resetStore: () => {
      set(() => ({
        ...initialState,
      }));
    },
    setSelectedDocument: (selectedDocument: DocumentType) => {
      setStateHelper("selectedDocument", selectedDocument, set);
    },
    setDocumentsList: (documentsList: DocumentType[]) => {
      setStateHelper("documentsList", documentsList, set);
    },
    setCompany: (company: CompanyType) => {
      setStateHelper("company", company, set);
    },
    setBulk: (bulk: BulkType) => {
      setStateHelper("bulk", bulk, set);
    },
    setLoadingPage: (loadingPage: boolean) => {
      setStateHelper("loadingPage", loadingPage, set);
    },
    setLoadingDocuments: (loadingDocuments: boolean) => {
      setStateHelper("loadingDocuments", loadingDocuments, set);
    },
    setDisablePageButtons: (disablePageButtons: boolean) => {
      setStateHelper("disablePageButtons", disablePageButtons, set);
    },
    setRefetchQueries: (refetchQueries: boolean) => {
      setStateHelper("refetchQueries", refetchQueries, set);
    },
    setScreens: (screens: Partial<Record<Breakpoint, boolean>>) => {
      setStateHelper("screens", screens, set);
    },
    setShowPreview: (showPreview: boolean) => {
      setStateHelper("showPreview", showPreview, set);
    },
    setBulkOwnerChanged: (bulkOwnerChanged: boolean) => {
      setStateHelper("bulkOwnerChanged", bulkOwnerChanged, set);
    },
  }),
);

export default useBulkStore;
