import useUserDataStore from "./useUserDataStore";

const TOTAL_CAN_CHANGED_FORM = 7;
const TOTAL_CAN_VIEW_FORM = 10;

interface usePermissionsReturns {
  canAccessNeossi: ({ appSlug }: { appSlug: any }) => any;
  canValidate: any;
  canRegister: any;
  canViewVatManagement: any;
  canViewManagment: any;
  canViewAdministrator: any;
  canRollback: any;
  canDeleteBulk: any;
  canViewForm: () => any;
  canChangedForm: () => any;
  canDisplayRemarksInput: any;
  canImportData: any;
  canAccessNeoCoffre: any;
  isATC: boolean;
  isOPS: boolean;
}

export default function usePermissions(): usePermissionsReturns {
  const userData = useUserDataStore((state) => state.userData);
  const permissions = userData?.permissions;

  // Applications Permissions
  const canAccessNeossi = ({ appSlug }) =>
    permissions &&
    permissions &&
    !!permissions.find((item) => item === `${appSlug}.can-access`);

  const canValidate =
    permissions &&
    permissions.find(
      (item) =>
        item === "neossi-api.can-validate-book-entries" ||
        item === "neossi.can-validate",
    );
  const canRegister =
    permissions &&
    permissions.find((item) => item === "neossi-api.can-register-book-entries");

  const canViewVatManagement =
    permissions &&
    permissions.find((item) => item === "neossi.can-display-vat-management");

  const canViewManagment =
    permissions &&
    permissions.find((item) => item === "neossi-api.can-view-management");

  const canViewAdministrator =
    permissions &&
    permissions.find((item) => item === "neossi-api.can-view-administration");

  const canRollback =
    permissions &&
    permissions.find((item) => item === "neodocs-api.can-rollback-bulks");

  const canDeleteBulk =
    permissions &&
    permissions.find(
      (item) => item === "neossi.can-display-button-remove-bulk",
    );

  const canViewForm = () => {
    const permitionsList =
      permissions &&
      permissions.filter(
        (item) =>
          item === "neossi-api.can-create-book-entries" ||
          item === "neossi-api.can-view-book-entries" ||
          item === "neossi-api.can-view-entry-vats" ||
          item === "neossi-api.can-view-entry-items" ||
          item === "central-api.can-view-books" ||
          item === "central-api.can-view-providers" ||
          item === "central-api.can-view-clients" ||
          item === "central-api.can-view-counter-parts" ||
          item === "central-api.can-view-vats" ||
          item === "central-api.can-view-subsidiaries",
      );
    return permissions && permitionsList.length;
  };

  const canChangedForm = () => {
    const permitionsList =
      permissions &&
      permissions.filter(
        (item) =>
          item === "neossi-api.can-delete-entry-vats" ||
          item === "neossi-api.can-delete-entry-items" ||
          item === "neossi-api.can-update-entry-vats" ||
          item === "neossi-api.can-create-entry-vats" ||
          item === "neossi-api.can-update-entry-items" ||
          item === "neossi-api.can-create-entry-items" ||
          item === "neossi-api.can-update-book-entries",
      );
    return permissions && permitionsList.length;
  };

  const canDisplayRemarksInput =
    permissions &&
    permissions.find((item) => item === "neossi.can-display-remarks-input");

  const canImportData =
    permissions &&
    permissions.find((item) => item === "central-api.can-import");

  const canAccessNeoCoffre = permissions?.find(
    (item) => item === "neocoffre.can-access",
  );

  const isATC = canRegister && !canValidate;
  const isOPS = canValidate;

  return {
    canAccessNeossi,
    canValidate,
    canRegister,
    canViewVatManagement,
    canViewManagment,
    canViewAdministrator,
    canRollback,
    canDeleteBulk,
    canViewForm,
    canChangedForm,
    canDisplayRemarksInput,
    canImportData,
    canAccessNeoCoffre,
    isATC,
    isOPS,
  };
}
